<template>
  <div>
    <h2>{{ $t('views.release_notes.h2_title') }} {{ release.version }}</h2>
    <p class="text-caption">{{ $t('views.release_notes.p_release_date') }} {{ formatDate(release.date) }}</p>
    <p>{{ $t('views.release_notes.p_release_note', { version: release.version }) }}</p>
    <div>
      <v-img class="mx-auto my-9" max-height="300" max-width="300" :src="getImageUrl()" />
    </div>
    <h3>{{ $t('views.release_notes.h3_new_features') }}</h3>
    <p>{{ $t('views.release_notes.release_2024_3.p_text_1') }}</p>
    <h3>Weitere Änderungen</h3>
    <v-simple-table class="mb-6">
      <template #default>
        <thead>
          <tr>
            <th class="text-center">{{ $t('views.release_notes.th_type') }}</th>
            <th class="text-center">{{ $t('views.release_notes.th_key') }}</th>
            <th class="text-left">{{ $t('views.release_notes.th_summary') }}</th>
            <th class="text-center">{{ $t('views.release_notes.th_version') }}</th>
            <th class="text-center">{{ $t('views.release_notes.th_date') }}</th>
            <th class="text-center">{{ $t('views.release_notes.th_status') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="releaseItem in releaseItems" :key="releaseItem.key">
            <td class="text-center"><fa-icon :icon="['fal', releaseItem.type]" /></td>
            <td class="text-center">{{ releaseItem.key }}</td>
            <td class="text-left">{{ releaseItem.summary }}</td>
            <td class="text-center">{{ releaseItem.version }}</td>
            <td class="text-center">{{ formatDate(new Date(releaseItem.date)) }}</td>
            <td class="text-center"><fa-icon :icon="['fal', releaseItem.status]" /></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { copyright, settings, releases } from '@/config'
import { webp } from '@/mixins'

export default {
  name: 'Release20243',
  mixins: [webp],
  inject: ['theme'],
  data() {
    return {
      release: {
        version: '2024.3',
        date: new Date('2024-04-02'),
      },
    }
  },
  computed: {
    copyright() {
      return copyright
    },
    settings() {
      return settings
    },
    releaseItems() {
      return releases['release-2024-3']
    },
  },
  methods: {
    getImageUrl() {
      return this.webpExtension(`${settings.cdn.url}academy.jpg`)
    },
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style scoped></style>

import { render, staticRenderFns } from "./release2024_1.vue?vue&type=template&id=15db0593&scoped=true&"
import script from "./release2024_1.vue?vue&type=script&lang=js&"
export * from "./release2024_1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15db0593",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VImg,VSimpleTable})

<template>
  <div>
    <h1>{{ $t('views.release_notes.h1_title') }}</h1>
    <Release20243 v-if="$route.path === '/de/versionshinweise/2024-3'"></Release20243>
    <Release20242 v-if="$route.path === '/de/versionshinweise/2024-2'"></Release20242>
    <Release20241 v-if="$route.path === '/de/versionshinweise/2024-1'"></Release20241>
    <Release20240 v-if="$route.path === '/de/versionshinweise/2024-0'"></Release20240>
    <Release20230 v-if="$route.path === '/de/versionshinweise/2023-0'"></Release20230>
    <Release20212 v-if="$route.path === '/de/versionshinweise/2021-2'"></Release20212>
    <Release20211 v-if="$route.path === '/de/versionshinweise/2021-1'"></Release20211>
    <div v-if="$route.path === '/de/versionshinweise'">
      <p>
        {{ $t('views.release_notes.p_text', { title: $t('globals.title') }) }}
      </p>
      <div class="text-center my-3">
        <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
      </div>
      <h2>{{ $t('views.release_notes.h2_index') }}</h2>

      <div v-for="release in releases" :key="release.year">
        <h3>{{ release.year }}</h3>
        <ul>
          <li v-for="item in release.items" :key="item.version">
            <Hyperlink :href="$t('hyperlinks.release_notes.href') + item.path" :aria-label="$t('hyperlinks.release_notes.purpose')"
              >Release {{ item.version }}</Hyperlink
            ><br />
            <span class="text-caption">veröffentlicht am {{ formatDate(item.date) }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center mt-6">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home.vue'
import Release20243 from '@/components/release-notes/release2024_3'
import Release20242 from '@/components/release-notes/release2024_2'
import Release20241 from '@/components/release-notes/release2024_1'
import Release20240 from '@/components/release-notes/release2024_0'
import Release20230 from '@/components/release-notes/release2023_0'
import Release20212 from '@/components/release-notes/release2021_2'
import Release20211 from '@/components/release-notes/release2021_1'
import Hyperlink from '@/components/Hyperlink.vue'
import { settings } from '@/config'

export default {
  name: 'Changelog',
  components: {
    Adslot,
    BtnHome,
    Release20243,
    Release20242,
    Release20241,
    Release20240,
    Release20230,
    Release20212,
    Release20211,
    Hyperlink,
  },
  data() {
    return {
      page: 1,
      releases: [
        {
          year: '2021',
          items: [
            {
              version: '2021.2',
              path: '/2021-2',
              date: new Date('2021-03-28'),
            },
            {
              version: '2021.1',
              path: '/2021-1',
              date: new Date('2021-03-23'),
            },
          ],
        },
        {
          year: '2023',
          items: [
            {
              version: '2023.0',
              path: '/2023-0',
              date: new Date('2023-02-19'),
            },
          ],
        },
        {
          year: '2024',
          items: [
            {
              version: '2024.0',
              path: '/2024-0',
              date: new Date('2024-02-10'),
            },
            {
              version: '2024.1',
              path: '/2024-1',
              date: new Date('2024-03-23'),
            },
            {
              version: '2024.2',
              path: '/2024-2',
              date: new Date('2024-03-26'),
            },
            {
              version: '2024.3',
              path: '/2024-3',
              date: new Date('2024-04-02'),
            },
          ],
        },
      ],
    }
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(() => {
          this.$vuetify.goTo(0)
        })
      }
    },
  },
  methods: {
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style scoped></style>
